/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import AutoSummit from "@components/sharedComponents/customersLogos/logos/AutoSummit.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Kia from "@components/sharedComponents/customersLogos/logos/Kia.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"
import Voge from "@components/sharedComponents/customersLogos/logos/Voge.svg"
import Aquaexport from "@components/sharedComponents/customersLogos/logos/Aquaexport.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/fidelizar-clientes-concesionaria.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/atencion-cliente-concesionarias.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/estrategias-marketing-automotriz.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/plan-marketing-ventas-autos.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/estrategias-ventas-proveedores-vehiculos.jpg"

// logos testimonios
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"

//foto testimonio desktop
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageConcesionarias/images/imageAccordion/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageConcesionarias/images/imageAccordion/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageConcesionarias/images/imageAccordion/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageConcesionarias/images/imageAccordion/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageConcesionarias/images/imageConvertions/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageConcesionarias/images/imageConvertions/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageConcesionarias/images/imageConvertions/imageThree.webp"
import imageConversionsFour from "@components/pageIndustrias/pageConcesionarias/images/imageConvertions/imageFour.webp"


import imageVideo from "@components/sharedComponents/bannerVideo/image/imageConcesionarias.jpg"

import TitleUnderlineStart from "@components/pageIndustrias/pageConcesionarias/titleUnderline/titleUnderlineStart"
import imageStartContactCenter from "@components/pageIndustrias/pageConcesionarias/images/imageStart.webp"


const titleStart = (
    <p>
        Suma automatización, omnicanalidad e IA para mejorar la experiencia de <TitleUnderlineStart underline="compra de " /><TitleUnderlineStart underline=" vehículos" />
    </p>
)

let data = {
    start: {
        title: titleStart,
        textbody: (
            <p>
                Optimiza los procesos de tu concesionaria con una plataforma de automatización omnicanal. Integra webchat y autoservicio con IA, y despliega campañas de email marketing automatizadas. Mejora el seguimiento de prospectos con workflows inteligentes.
                <br /><br />
                Envía recordatorios de mantenimiento, enruta y escalas llamadas eficientemente, y recibe solicitudes de crédito 24/7. Facilita la programación de citas y ofrece un servicio que fideliza a tus clientes.
            </p>
        ),
        image: imageStartContactCenter,
        textbutton: "Habla con un experto",
        link: "/hablemos/",
    },
    customersDesktop: [
        AutoSummit,
        Astara,
        Kia,
        Royal,
        Voge,
        Aquaexport
    ],
    customersTablet: {
        arrayOne: [AutoSummit, Astara, Kia, Royal, Voge, Aquaexport],
    },
    accordionRight: [
        {
            title: "Automatiza seguimiento de captura de leads",
            description: "<p>Acelera el seguimiento de leads capturados desde múltiples canales diseñando un flujo de mensajes personalizados que se activen de acuerdo con la fase de embudo de compra o el interés de tus leads.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Agiliza la gestión de citas y pruebas de conducción",
            description: "<p>Facilita la programación de citas y pruebas de conducción por medio de canales digitales como WhatsApp y asegúrate que puedan elegir el mejor momento para ellos. Reduce las ausencias enviando recordatorios automáticos de citas.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Recibe solicitudes de crédito 24/7",
            description: "<p>Permite a tus clientes la posibilidad de solicitar crédito en cualquier momento desde tus canales de atención. Gestiona sus documentos desde un solo lugar y mantelos informados enviando notificaciones automáticas sobre el estado de sus solicitudes.</p>",
            image: ImageAccordionLeftThree,
        },
        {
            title: "Añade tus herramientas internas",
            description: "<p>Centraliza tu gestión integrando tu CRM, ERP y DMS, con todos tus canales de atención. Asegura que toda la información esté siempre actualizada y accesible para tu equipo. Obtén una vista unificada de cada cliente potencial.</p>",
            image: ImageAccordionLeftFour
        },
    ],
    conversions: [
        {
            title: "Integra webchat con IA",
            img: imageConversionsOne,
            text: "<p>Añade un webchat con IA que funcione como un asistente en tiempo real que le ayude a tus clientes encontrar el vehículo que más se acerca a sus preferencias. Además, responde las preguntas frecuentes con bots.</p>"
        },
        {
            title: "Mejora el seguimiento de prospectos",
            img: imageConversionsTwo,
            text: "<p>Mantén un contacto constante y personalizado con cada lead, aumentando las probabilidades de cierre. Despliega mensajes personalizados dependiendo de los intereses del cliente potencial en cada fase del embudo de ventas.</p>",
        },
        {
            title: "Brinda un autoservicio con Inteligencia Artificial",
            img: imageConversionsThree,
            text: "<p>Utiliza chatbots e IVRs para que tus clientes gestionen sus propias consultas y solicitudes en cualquier momento. Reduce la carga de trabajo de tu equipo, automatizando las respuestas a las preguntas más frecuentes y liberando tiempo para casos más complejos.</p>",
        },
        {
            title: "Utiliza workflows con IA",
            img: imageConversionsFour,
            text: "<p>Desde la captura de leads hasta la gestión de ventas, optimiza cada paso del proceso. Aumenta la eficiencia de tu equipo con flujos de trabajo inteligentes que priorizan y asignan tareas automáticamente, asegurando que siempre se enfoquen en las actividades de mayor impacto.</p>",
        },
    ],

    articles: [
        {
            image: article1,
            title: "¿Cómo fidelizar clientes en la concesionaria? 6 tips para lograrlo",
            textbody:
                "Te contamos cuáles son las estrategias claves para fidelizar a los clientes dentro de una concesionaria y así no lo llegues a perder.",
            link: "https://beexcc.com/blog/fidelizar-clientes-concesionaria/",
            card: true,
        },
        {
            image: article2,
            title: "¿Cómo mejorar la atención al cliente para las concesionarias?",
            textbody:
                "Entérate cuáles son las mejores formas de mejorar la atención al cliente para las concesionarias para fidelizar y vender mucho más.",
            link: "https://beexcc.com/blog/atencion-cliente-concesionarias/",
            card: true,
        },
        {
            image: article3,
            title: "10 estrategias de marketing automotriz",
            textbody:
                "¿Buscas estrategias de marketing automotriz? Conoce estas estrategias y consejos para ganar la confianza de tus leads. ¡ENTRA AQUÍ y arrasa con las ventas!",
            link: "https://beexcc.com/blog/estrategias-marketing-automotriz/",
        },
        {
            image: article4,
            title: "9 tips para tu plan de marketing para venta de autos",
            textbody:
                "Te contamos 9 tips para tu plan de marketing para venta de autos y así puedas alcanzar con tus objetivos comerciales.",
            link: "https://beexcc.com/blog/plan-marketing-ventas-autos/",
        },
        {
            image: article5,
            title: "7 estrategias para ventas de proveedores de vehículos",
            textbody:
                "Te contamos 7 estrategias calves para aumentar las ventas de los proveedores de vehículos y así puedas alcanzar con tus objetivos.",
            link: "https://beexcc.com/blog/estrategias-ventas-proveedores-vehiculos/",
        },
    ],
    contacts: {
        title: "Conviértete en la concesionaria que convierte más con experiencias únicas",
        textbody: "Estás a un paso de revolucionar tus estrategias de atención al cliente y ventas. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: AstaraTes,
            title:
                "Astara triplicó sus ventas y redujo en un 93% su esfuerzo de remarketing ",
            link: "/casos-de-exito/astara/",
            industrie: "",
            backgroundIndustrie: "",
            backgroundFondo: "#231E3633",
            metrics: [
                {
                    metric: "x3",
                    descripcion: "ventas digitales crecieron",
                    color: "#FFB800",
                },
                {
                    metric: "93%",
                    descripcion: "menos esfuerzo en remarketing",
                    color: "#5831D8",
                },
                {
                    metric: "80%",
                    descripcion: "mejor contacto con leads",
                    color: "#00CA56",
                },
            ],
        },
    ],
    bannerVideo: {
        title: "¿Cómo mejorar el tratamiento de leads en una concesionaria?",
        description: "<p>Descubre estrategias que pueden transformar tu gestión de leads en una concesionaria. Analiza cómo optimizar procesos, desde la captación hasta el cierre de ventas. Explora técnicas que mejorarán la experiencia del cliente y aumentarán la efectividad de tu equipo. <br /><br /> Aprende métodos para mantener el interés y la lealtad de los potenciales compradores. </p>",
        image: imageVideo
    },

}

export default data
